import React from "react";
import styled from "styled-components";
import Container from "../../Components/Container";
import Row from "../../Components/Row";
import ScreensImage from "../../assets/images/screens.png";
import Button from "../../Components/Button";
import PlayStoreIcon from "../../assets/images/google-play.svg";
import AppleIcon from "../../assets/images/apple.svg";

const Title = styled.h1`
  font-weight: 700;
  font-size: 65px;
`;

const SpanText = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`;

const Home = () => {
  return (
    <div>
      <Container style={{ textAlign: "center" }} id="section-1">
        <Title>
          Um jeito <span style={{ color: "#4162FD" }}>simples</span> de alugar.
        </Title>
        <SpanText>Simplifique a gestão dos seus aluguéis.</SpanText>
        <br />
        <SpanText style={{ fontWeight: 300 }}>
          Crie contratos, assine digitalmente e gerencie cobranças
          <br /> com facilidade.
        </SpanText>
        <Row
          xs="1fr auto 1fr"
          sm="1fr auto 1fr"
          md="1fr auto 1fr"
          lg="1fr auto 1fr"
          xl="1fr auto 1fr"
          style={{ marginTop: "20px" }}
        >
          <div></div>
          <img
            src={ScreensImage}
            alt="Celulares"
            width={"70%"}
            style={{ margin: "auto" }}
          />
          <div></div>
        </Row>
        <Row
          xs="1fr 200px 1fr"
          sm="1fr 200px 1fr"
          md="1fr 390px 1fr"
          lg="1fr 390px 1fr"
          xl="1fr 390px 1fr"
          style={{ marginTop: "20px" }}
        >
          <div></div>
          <Row
            xs="1fr"
            sm="1fr"
            md="1fr 1fr"
            lg="1fr 1fr"
            xl="1fr 1fr"
            style={{ marginTop: "30px", marginBottom: "200px" }}
          >
            <Button style={{ borderRadius: "40px" }}>
              <img
                src={PlayStoreIcon}
                width={"20px"}
                style={{ marginRight: "10px" }}
              />
              Google Play
            </Button>
            <Button style={{ borderRadius: "40px" }}>
              <img
                src={AppleIcon}
                width={"20px"}
                style={{ marginRight: "10px" }}
              />
              App Store
            </Button>
          </Row>
          <div></div>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
