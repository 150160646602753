import styled from 'styled-components';

interface RowProps {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
  gap?: string;
}

const Row = styled.div<RowProps>`
  display: grid;
  gap: ${(props) => props.gap || '16px'};
  grid-template-columns: ${(props) => props.xs || '1fr'};
  max-width: 1440px;
  margin: auto;

  @media (min-width: 576px) {
    grid-template-columns: ${(props) => props.sm || props.xs || '1fr'};
  }

  @media (min-width: 768px) {
    grid-template-columns: ${(props) => props.md || props.sm || props.xs || '1fr'};
  }

  @media (min-width: 992px) {
    grid-template-columns: ${(props) => props.lg || props.md || props.sm || props.xs || '1fr'};
  }

  @media (min-width: 1200px) {
    grid-template-columns: ${(props) => props.xl || props.lg || props.md || props.sm || props.xs || '1fr'};
  }

  @media (min-width: 1400px) {
    grid-template-columns: ${(props) => props.xxl || props.xl || props.lg || props.md || props.sm || props.xs || '1fr'};
  }
`;

export default Row;
