import React from "react";
import styled from "styled-components";
import Container from "../../Components/Container";
import Row from "../../Components/Row";
import Button from "../../Components/Button";
import PlayStoreIcon from "../../assets/images/google-play.svg";
import AppleIcon from "../../assets/images/apple.svg";
import QRCode from '../../assets/images/qr_code.svg'
import LogoBlue from '../../assets/images/logo-blue.svg'

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  color: #ffffff;
  line-height: 56px;
`;

const SpanText = styled.span`
  font-weight: 200;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
`;

const Footer = () => {
  return (
    <>
      <Container
        style={{
          marginTop: "40px",
          backgroundColor: "#1E1E1E",
        }}
        id="section-3"
      >
        <Row
          xs="1fr"
          sm="1fr"
          md="60% auto"
          lg="60% auto"
          xl="60% auto"
          style={{ marginTop: "60px", marginBottom: "60px" }}
          gap="170px"
        >
          <div>
            <Title>
              Pronto para <span style={{ color: "#41FFA4" }}>revolucionar</span>{" "}
              a gestão dos seus imóveis?
            </Title>
            <SpanText>Cadastre-se agora e experimente o Room</SpanText>
            <Row
              xs="1fr"
              sm="1fr"
              md="1fr 1fr"
              lg="1fr 1fr"
              xl="1fr 1fr"
              style={{ marginTop: "30px", maxWidth: '340px' }}
            >
              <Button style={{ borderRadius: "40px", backgroundColor: 'transparent', border: '1px solid #ffffff' }}>
                <img
                  src={PlayStoreIcon}
                  width={"20px"}
                  style={{ marginRight: "10px" }}
                />
                Google Play
              </Button>
              <Button style={{ borderRadius: "40px", backgroundColor: 'transparent', border: '1px solid #ffffff' }}>
                <img
                  src={AppleIcon}
                  width={"20px"}
                  style={{ marginRight: "10px" }}
                />
                App Store
              </Button>
            </Row>
          </div>
          <img src={QRCode} width={'100%'} />
        </Row>
      </Container>
      <Container
        style={{
          textAlign: "center",
        }}
        id="section-3"
      >
        <Row
          xs="1fr 1fr"
          sm="1fr 1fr"
          md="1fr 1fr"
          lg="1fr 1fr"
          xl="1fr 1fr"
          style={{ marginTop: "40px", marginBottom: '30px', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <img src={LogoBlue} width={'53px'} />
          <span style={{fontWeight: 200, textAlign: 'right'}}>© 2025 Room</span>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
