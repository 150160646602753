import styled from "styled-components";

const Container = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 20px calc((100% - 70.30%) / 2);

  @media (min-width: 576px) {
    padding: 20px calc((100% - 70.30%) / 2);
  }

  @media (min-width: 768px) {
    padding: 20px calc((100% - 70.30%) / 2);
  }

  @media (min-width: 992px) {
    padding: 20px calc((100% - 70.30%) / 2);
  }

  @media (min-width: 1200px) {
    padding: 20px calc((100% - 70.30%) / 2);
  }

  @media (min-width: 1400px) {
    padding: 20px calc((100% - 70.30%) / 2);
  }
`;

export default Container;
