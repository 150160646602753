import React from "react";
import styled from "styled-components";
import Container from "../../Components/Container";
import Row from "../../Components/Row";
import DolarIcon from "../../assets/images/dolar.svg";
import PaperIcon from "../../assets/images/paper.svg";
import SignIcon from "../../assets/images/sign.svg";
import CoinIcon from "../../assets/images/coin.svg";
import PhoneImage from "../../assets/images/phone.png";

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  margin-top: -180px;
`;

const TitleCard = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`;

const SubtitleCard = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
`;

const Card = styled.div`
  background-color: #e8f8ff;
  color: #1e1e1e;
  border-radius: 26px;
  padding: 35px;
  text-align: left;
`;

const ContainerDiv = styled.div`
  border-left: 2px solid #ffffff;
  position: relative;
  min-height: 100%;

  @media (min-width: 576px) {
    min-height: 180%;
  }

  @media (min-width: 768px) {
    min-height: 170%;
  }

  @media (min-width: 992px) {
    min-height: 50%;
  }

  @media (min-width: 1200px) {
    min-height: 60%;
  }

  @media (min-width: 1400px) {
    min-height: 68%;
  }
`;

const ImagePhone = styled.img`
  margin-top: 460px;

  @media (min-width: 576px) {
    margin-top: 460px;
  }

  @media (min-width: 768px) {
    margin-top: 460px;
  }

  @media (min-width: 992px) {
    margin-top: 0px;
    justify-self: end;
  }

  @media (min-width: 1200px) {
    margin-top: 0px;
    justify-self: end;
  }

  @media (min-width: 1400px) {
    margin-top: 0px;
    justify-self: end;
  }
`;

const CircleDiv = styled.div`
  width: 22px;
  height: 22px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CircleIntoDiv = styled.div`
  width: 12px;
  height: 12px;
  background-color: #4162fd;
  border-radius: 50%;
`;

const BodyTexts = styled.div`
  position: absolute;
  text-align: left;
  color: #ffffff;
`;

const cardData = [
  {
    title: "Cobranças automatizadas",
    subtitle: "Receba os aluguéis via PIX, sem complicações.",
    icon: DolarIcon,
    iconWidth: "40px",
  },
  {
    title: "Contratos rápidos",
    subtitle: "Crie contratos personalizados em minutos para cada imóvel.",
    icon: PaperIcon,
    iconWidth: "30px",
  },
  {
    title: "Assinatura digital",
    subtitle: "Validade jurídica garantida, 100% digital e segura.",
    icon: SignIcon,
    iconWidth: "80px",
  },
  {
    title: "Gestão de pagamentos",
    subtitle: "Tenha controle total dos pagamentos, em qualquer lugar.",
    icon: CoinIcon,
    iconWidth: "40px",
  },
];

const About = () => {
  return (
    <Container style={{ textAlign: "center", backgroundColor: "#4162FD" }} id="section-2">
      <Title>
        Tudo o que você <span style={{ color: "#4162FD" }}>precisa</span> em um
        só lugar.
      </Title>
      <Row
        xs="1fr"
        sm="1fr"
        md="1fr"
        lg="1fr 1fr"
        xl="1fr 1fr"
        style={{ marginTop: "40px" }}
      >
        {cardData.map((card, index) => (
          <Card key={index}>
            <Row
              xs="1fr"
              sm="1fr"
              md="80% auto"
              lg="80% auto"
              xl="80% auto"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <div>
                <TitleCard>{card.title}</TitleCard>
                <br />
                <SubtitleCard>{card.subtitle}</SubtitleCard>
              </div>
              <img src={card.icon} width={card.iconWidth} alt={card.title} />
            </Row>
          </Card>
        ))}
      </Row>
      <Row
        xs="1fr"
        sm="1fr"
        md="1fr"
        lg="60% auto"
        xl="60% auto"
        style={{ marginTop: "60px", paddingRight: "40px", paddingLeft: "40px" }}
      >
        <div>
          <Title style={{ marginTop: 0, textAlign: "left", color: "#ffffff" }}>
            3 passos simples para começar
          </Title>
          <br />
          <br />
          <br />
          <ContainerDiv>
            <CircleDiv style={{ top: 0, left: -12 }}></CircleDiv>
            <CircleDiv style={{ top: "50%", left: -12 }}>
              <CircleIntoDiv></CircleIntoDiv>
            </CircleDiv>
            <CircleDiv style={{ top: "100%", left: -12 }}></CircleDiv>
            <BodyTexts style={{ top: '-3%', left: 40 }}>
              <TitleCard>Crie sua conta</TitleCard>
              <br />
              <SubtitleCard>
                Cadastre-se gratuitamente e tenha acesso a todas as
                funcionalidades.
              </SubtitleCard>
            </BodyTexts>
            <BodyTexts style={{ top: "47%", left: 40 }}>
              <TitleCard>Adicione seus imóveis</TitleCard>
              <br />
              <SubtitleCard>
                Configure os detalhes do imóvel e do contrato.
              </SubtitleCard>
            </BodyTexts>
            <BodyTexts style={{ top: "97%", left: 40 }}>
              <TitleCard>Gerencie seus aluguéis</TitleCard>
              <br />
              <SubtitleCard>
              Assine contratos, automatize cobranças e monitore tudo no app.
              </SubtitleCard>
            </BodyTexts>
          </ContainerDiv>
        </div>
        <ImagePhone src={PhoneImage} width={"80%"} />
      </Row>
      <br />
          <br />
          <br />
      <br />
          <br />
          <br />
    </Container>
  );
};

export default About;
